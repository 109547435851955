import Polyglot from 'node-polyglot';
import { setStorageKey, StorageKeys } from './storage';
import {
  GroupDocumentWithSubscription,
  LocaleString,
  TranslateKeys,
  TranslationObject,
} from '@og-shared/types';
import { exhaustiveCheck } from '@og-shared/utils';

const polyglot = new Polyglot();

export const locales: LocaleString[] = [
  'en',
  // 'de',
  'es',
  // 'fr',
  // 'it',
  // 'pt',
  // 'ru',
  // 'tr',
  // 'hi',
  // 'ja',
  // 'zh',
  // 'ko'
];

function getLanguageTranslations() {
  const languages: TranslationObject = {
    en: 'English',
    es: 'Español',
  };
  return languages;
}

async function getAppTranslations(locale: LocaleString) {
  const validLocale = locale;
  switch (validLocale) {
    case 'en': {
      const translationFile = await import('./locales/en');
      return { validLocale, translations: translationFile.default };
    }
    case 'es': {
      const translationFile = await import('./locales/es');
      return { validLocale, translations: translationFile.default };
    }
    default:
      exhaustiveCheck(validLocale);
  }
  const translationFile = await import('./locales/en');
  return {
    validLocale: 'en' as LocaleString,
    translations: translationFile.default,
  };
}

export async function initPolyglot(locale: LocaleString) {
  const { translations, validLocale } = await getAppTranslations(locale);

  await setStorageKey(StorageKeys.og_locale, validLocale);

  polyglot.extend(getLanguageTranslations());
  polyglot.extend(translations);
}

export async function extendTranslations(translations: any) {
  if (!translations) return;
  polyglot.extend(translations);
}

export const translate = (key: TranslateKeys | LocaleString, params?: any) => {
  return polyglot.t(key, params) as string;
};

export function translatePlaceholder(s: string) {
  // @todo translate
  console.warn(`translate placeholder: ${s}`);
  return s;
}

export function getEmptyTranslationObject() {
  const translations: TranslationObject = {
    en: '',
    es: '',
  };
  return translations;
}

export function getTranslationFromObject(
  translationObject: TranslationObject,
  key: LocaleString
) {
  if (!translationObject) return undefined;
  if (translationObject[key]) {
    return translationObject[key];
  }
  return translationObject.en;
}

export function getStoreName(store: GroupDocumentWithSubscription['paid_via']) {
  switch (store) {
    case 'ios': {
      return 'App Store';
    }
    case 'android': {
      return 'Play Store';
    }
    case 'partner': {
      return translate('PAID_VIA_PARTNER');
    }
    case 'stripe': {
      return 'Stripe;';
    }
    case null: {
      return translate('OG_UNKNOWN');
    }
    default: {
      exhaustiveCheck(store);
      return '';
    }
  }
}
