import { Preferences } from '@capacitor/preferences';
import { exhaustiveCheck } from '@og-shared/utils';
import { LocaleString } from '@og-shared/types';

import { LinkSuccessPath } from '../services/plaid-link';

export enum StorageKeys {
  og_account_created = 'og_account_created',
  og_active_group_id = 'og_active_group_id',
  og_first_launch = 'og_first_launch',
  og_frequency_view = 'og_frequency_view',
  og_home_page = 'og_home_page',
  og_invited_by_uid = 'og_invited_by_uid',
  og_link_success_path = 'og_link_success_path',
  og_link_token = 'og_link_token',
  og_locale = 'og_locale',
  og_partner_id = 'og_partner_id',
  og_projection_segment = 'og_projection_segment',
  og_utm_campaign = 'og_utm_campaign',
  og_utm_content = 'og_utm_content',
  og_utm_medium = 'og_utm_medium',
  og_utm_source = 'og_utm_source',
}

export function setStorageKey<T extends StorageKeys>(
  key: T,
  inputValue: StorageTypeMap[T]
) {
  let value = inputValue as any;
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  return Preferences.set({ key, value });
}

export async function getStorageKey<T extends StorageKeys>(
  key: T & StorageKeys
): Promise<StorageTypeMap[T]> {
  const { value } = await Preferences.get({ key });
  switch (key) {
    case StorageKeys.og_locale:
      return (value as any) ?? 'en';
    case StorageKeys.og_invited_by_uid:
      return value as any;
    case StorageKeys.og_active_group_id:
      return value as any;
    case StorageKeys.og_home_page:
      return value as any;
    case StorageKeys.og_link_token:
      return value as any;
    case StorageKeys.og_account_created:
      return (value ? true : false) as any;
    case StorageKeys.og_link_success_path:
      return value as any;
    case StorageKeys.og_partner_id:
      return value as any;
    case StorageKeys.og_projection_segment: {
      const validOptions = [1, 2, 4, 6, 12];
      const defaultValue = 1;
      const numberValue = Number(value);
      return (
        validOptions.includes(numberValue) ? numberValue : defaultValue
      ) as any;
    }
    case StorageKeys.og_utm_source:
      return value as any;
    case StorageKeys.og_utm_medium:
      return value as any;
    case StorageKeys.og_utm_campaign:
      return value as any;
    case StorageKeys.og_utm_content:
      return value as any;
    case StorageKeys.og_first_launch: {
      return (value ? false : true) as any;
    }
    case StorageKeys.og_frequency_view: {
      const validOptions: BudgetFrequencyView[] = [1, 12, 52];
      const defaultValue = 12;
      const numberValue = Number(value) as BudgetFrequencyView;
      return (
        validOptions.includes(numberValue) ? numberValue : defaultValue
      ) as any;
    }
    default:
      return exhaustiveCheck(key);
  }
}

export function removeStorageKey(key: StorageKeys) {
  return Preferences.remove({ key });
}

export function clearStorage() {
  return Preferences.clear();
}

type StorageTypeMap = {
  [StorageKeys.og_locale]: LocaleString;
  [StorageKeys.og_invited_by_uid]: string | null;
  [StorageKeys.og_active_group_id]: string | null;
  [StorageKeys.og_home_page]: AuthState['home_page'] | null;
  [StorageKeys.og_account_created]: boolean;
  [StorageKeys.og_link_token]: string | null;
  [StorageKeys.og_link_success_path]: LinkSuccessPath | null;
  [StorageKeys.og_utm_source]: string | null;
  [StorageKeys.og_utm_medium]: string | null;
  [StorageKeys.og_utm_campaign]: string | null;
  [StorageKeys.og_utm_content]: string | null;
  [StorageKeys.og_partner_id]: string | null;
  [StorageKeys.og_projection_segment]: 1 | 2 | 4 | 12;
  [StorageKeys.og_first_launch]: boolean;
  [StorageKeys.og_frequency_view]: BudgetFrequencyView;
};

export async function saveStorageKeyIfNotPresent<T extends StorageKeys>(
  key: T,
  newValue: StorageTypeMap[T]
) {
  const storedValue = await getStorageKey(key);
  if (storedValue !== null) return; // already stored
  await setStorageKey(key, newValue);
}
